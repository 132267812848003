<template>
  <AppHeader />
  <Suspense>
    <template #default>
       <Comedys />
    </template>
    <template #fallback>
       <Loding />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
import { defineAsyncComponent, Suspense } from "vue";
import Loding from "@/components/Include/Loding.vue";
import AppHeader from "@/components/Include/AppHeader.vue";
import AppFooter from "@/components/Include/AppFooter.vue";
const Comedys = defineAsyncComponent(() =>
  import('@/components/Categorys/Comedys.vue')
)
export default {
  name: 'Comedy',
   components: {
    Suspense,
    Loding,
    AppHeader,
    AppFooter,
    Comedys
  },
}
</script>

<style>

</style>